import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useInput, FieldTitle } from "react-admin";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import { es } from "date-fns/locale";
import { useSearchParams } from "react-router-dom";

const Picker = ({ PickerComponent, ...fieldProps }) => {
  const {
    options,
    label,
    source,
    resource,
    className,
    isRequired,
    shouldDisableDate,
  } = fieldProps;

  const { field, fieldState } = useInput({ source });

  const { isTouched, error } = fieldState;

  const handleChange = useCallback((value) => {
    Date.parse(value)
      ? field.onChange(value.toISOString())
      : field.onChange(null);
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className="picker">
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <DesktopDatePicker
          {...options}
          format="dd/MM/yyyy"
          shouldDisableDate={shouldDisableDate}
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          margin="normal"
          error={!!(isTouched && error)}
          helperText={isTouched && error}
          className={className}
          value={
            field.value
              ? new Date(field.value)
              : searchParams.get("date")
              ? new Date(searchParams.get("date"))
              : null
          }
          onChange={(date) => handleChange(date)}
          onBlur={() =>
            field.onBlur(
              field.value ? new Date(field.value).toISOString() : null
            )
          }
          renderInput={(params) => <TextField {...params} fullWidth/>}
        />
      </LocalizationProvider>
    </div>
  );
};

Picker.propTypes = {
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  labelTime: PropTypes.string,
  className: PropTypes.string,
  providerOptions: PropTypes.shape({
    utils: PropTypes.func,
    locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  shouldDisableDate: PropTypes.func,
};

Picker.defaultProps = {
  input: {},
  isRequired: false,
  meta: { touched: false, error: false },
  options: {},
  resource: "",
  source: "",
  labelTime: "",
  className: "",
  providerOptions: {
    utils: DateFnsUtils,
    locale: undefined,
  },
};

export const DateInput = (props) => (
  <Picker PickerComponent={DesktopDatePicker} {...props} />
);
